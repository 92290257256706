import React from "react"

const MenuItem = props => {
  return (
    <div className="menuItem flex">
      <div className="menuItem__cont w-full bg-white rounded-3xl p-6 text-left flex flex-col">
        <img
          className="menuItem__img rounded-3xl block relative mb-6"
          src={props.img_url}
          alt={props.img_alt}
        />
        <h3 className="menuItem__name mb-2 block">{props.title}</h3>
        <span className="menuItem__price block text-xs font-medium mb-6">
          {props.price}
        </span>
        <p className="menuItem__text text-base">{props.text}</p>
      </div>
    </div>
  )
}

export default MenuItem
