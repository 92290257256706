import React from "react"
import UnderLine from "../images/svgs/headerUnderLine.svg"

const Headline = props => {
  return (
    <div className="headline mb-14 md:mb-24 text-center relative">
      <h1>{props.title}</h1>
      <UnderLine className="inline" />
      <div
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="ease"
        className="headline-icon absolute primary-color"
      >
        {props.icon}
      </div>
    </div>
  )
}

export default Headline
