import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Donut from "../images/svgs/mini-donut.svg"
import Headline from "../components/headline"
import MenuItem from "../components/menu-item"
import IceCreamCup from "../images/svgs/ice-cream-cup.svg"
import IceCream from "../images/svgs/ice-cream-cone.svg"
import Map from "../components/googleMap"
import gelato from "../images/gelato.png"

export default function Menu({ data }) {
  //menu items
  const menuArray = data.allWpProduct.edges || []
  console.log(menuArray)
  //wp items
  const array = data.allWpPage.edges[0].node.menuPage || []
  //header
  const header = array.header || "Our Donuts"
  //Double boxes
  //first box array
  const firstBox = array.doubleBox.firstBox || []
  const secondBox = array.doubleBox.secondBox || []
  //Gelato
  const firstRow = array.gelato.firstRow || []
  const gFirstColumn = firstRow.columnOne || []
  const gSecondColumn = firstRow.columnTwo || []
  const secondRow = array.gelato.secondRow || []
  const fFirstColumn = secondRow.columnOne || []
  const fSecondColumn = secondRow.columnTwo || []

  return (
    <Layout>
      <SEO title="Menu" />
      <section className="menu mt-20">
        <div className="container">
          <Headline
            title={header}
            icon={
              <Donut className="invisible md:visible h-auto primary-color" />
            }
          />
        </div>
        <div className="blue-block pt-16 pb-6 md:pt-36">
          <div className="container text-center">
            <IceCream
              data-sal="zoom-in"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="ice-cream-1 invisible md:visible"
            />
            <IceCream
              data-sal="zoom-in"
              data-sal-delay="500"
              data-sal-easing="ease"
              className="ice-cream-2 invisible md:visible"
            />
            <IceCream
              data-sal="zoom-in"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="ice-cream-3 invisible md:visible"
            />

            <Donut
              data-sal="zoom-in"
              data-sal-delay="500"
              data-sal-easing="ease"
              className="donut-2 invisible xl:visible"
            />
            <IceCreamCup
              data-sal="zoom-in"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="iceCreamCup-1 invisible md:visible"
            />
            <IceCreamCup
              data-sal="zoom-in"
              data-sal-delay="500"
              data-sal-easing="ease"
              className="iceCreamCup-2 invisible md:visible"
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10">
              {menuArray
                .filter(
                  item => item.node.categories.nodes[0].name === "Uncategorized"
                )
                .map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      title={item.node.products.itemName}
                      price={`AUD ${item.node.products.price.toFixed(2)}`}
                      text={item.node.products.description}
                      img_url={item.node.products.itemImage.sourceUrl}
                      img_alt={item.node.products.itemImage.altText}
                    />
                  )
                })}
            </div>
            <h2 className="mt-10">Our Vegan Flavours</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10">
              {menuArray
                .filter(item => item.node.categories.nodes[0].name === "Vegan")
                .map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      title={item.node.products.itemName}
                      price={`AUD ${item.node.products.price.toFixed(2)}`}
                      text={item.node.products.description}
                      img_url={item.node.products.itemImage.sourceUrl}
                      img_alt={item.node.products.itemImage.altText}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="heroBoxes menuBox">
        <div className="container -mb-24">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10 gap-y-10 lg:gap-y-0 mx-auto pt-14 pb-14 lg:pb-0">
            <div className="icon-box bg-white rounded-3xl px-2 py-8  lg:-mb-32">
              {firstBox.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="iceBoxMenu">
                      <span className="item">{item.name}</span>
                      <span className="price">{`AUD ${item.price}`}</span>
                    </div>
                    {item.price ? <p>{item.description}</p> : ""}
                  </div>
                )
              })}
            </div>

            <div className="icon-box bg-white rounded-3xl px-2 py-8  lg:-mb-32">
              {secondBox.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="iceBoxMenu">
                      <span className="item">{item.name}</span>
                      <span className="price">{`AUD ${item.price}`}</span>
                    </div>
                    {item.price ? <p>{item.description}</p> : ""}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="gelato mt-40 md:mt-80">
        <div className="container">
          <Headline
            title="Premium Gelato"
            icon={
              <IceCreamCup className="invisible md:visible h-auto primary-color" />
            }
          />
          <img className="gelato-img" src={gelato} alt="" />
          <div className="infobox w-10/12">
            <p className="mb-0 text-white">
              Made Using Single Herd Guernsey Milk Fresh from the South West WA.
            </p>
            <p className="text-white">Vegan and Gluten Free available.</p>
            <div className="md:flex line-after">
              <div className="scoop w-full  md:w-1/2">
                {gFirstColumn.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="priceBox">
                        <span className="item">{item.name}</span>
                        <span className="price">{`AUD ${item.price}`}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="scoop w-full md:w-1/2 md:ml-20">
                {gSecondColumn.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="priceBox">
                        <span className="item">{item.name}</span>
                        <span className="price">{`AUD ${item.price}`}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="md:flex md:mt-4">
              <div className="scoop mt-5">
                <div className="priceBox">
                  <span className="item">{secondRow.title}</span>
                </div>
              </div>
            </div>
            <div className="md:flex flavours">
              <div className="scoop  w-full md:w-1/2">
                {fFirstColumn.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="priceBox">
                        <span className="item">{item.flavours}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="scoop w-full md:w-1/2">
                {fSecondColumn.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="priceBox">
                        <span className="item">{item.flavours}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <IceCream
                data-sal="zoom-in"
                data-sal-delay="300"
                data-sal-easing="ease"
                className="ice-cream invisible md:visible "
              />
            </div>
          </div>
        </div>
      </section>
      <section className="location mt-20 md:mt-30  ">
        <Headline
          title="Our Locations"
          icon={<Donut className="invisible md:visible h-auto primary-color" />}
        />
        <Map />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query productsQuery {
    allWpProduct {
      edges {
        node {
          products {
            fieldGroupName
            description
            itemName
            price
            itemImage {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: "cG9zdDozNA==" } }) {
      edges {
        node {
          title
          menuPage {
            header
            doubleBox {
              firstBox {
                description
                fieldGroupName
                price
                name
              }
              secondBox {
                description
                name
                price
              }
            }
            gelato {
              firstRow {
                columnOne {
                  name
                  price
                }
                columnTwo {
                  name
                  price
                }
              }
              secondRow {
                title
                columnOne {
                  flavours
                }
                columnTwo {
                  flavours
                }
              }
            }
          }
        }
      }
    }
  }
`
